import { ADMIN } from "../../pages/Accueil/Accueil";
import { Law } from "./Law";
import { User } from "./User";

export interface Job {
  uid: string;
  name: string;
}

export const JOB_DG = "6655d9d51c1234.99207572";
export const JOB_RAF = "6655d77e944ad1.99680373";
export const JOB_PDG = "6656e4c4da2e79.53803217";
export const JOB_DAF = "6683effb9555b5.55075437";

export const isDg = (user: User): boolean => user?.jobUid === JOB_DG;
export const isRaf = (user: User): boolean => user?.jobUid === JOB_RAF;
export const isDaf = (user: User): boolean => user?.jobUid === JOB_DAF;
// todo : temporaire
export const isRonan = (user: User): boolean => user?.uid === "62a2020b707fc7.24338742";
export const isManon = (user: User): boolean => user?.uid === "66799ef053c8c7.94670015";
export const isPdg = (user: User): boolean => user?.jobUid === JOB_PDG;
export const hasAdminRight = (law: any): boolean => law.some((law: Law) => law.uid === ADMIN);
