import styleModal from '../../components/tinyModal/tinyModal.module.scss';
import { Button, TextField } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import SendIcon from '@mui/icons-material/Send';
import { ADMIN, INFO, REJECT, VALID } from './Accueil';
import { useEffect, useState } from 'react';
import { Invest } from '../../services/object/Invest';
import { User } from '../../services/object/User';
import { isDg, isDaf, isPdg, isRonan, JOB_DAF, JOB_DG, JOB_PDG, JOB_RAF, hasAdminRight, isRaf, isManon } from '../../services/object/Job';
import { useLaw } from '../../services/auth/useLaw';
import { Law } from '../../services/object/Law';

interface SalepointTinyPopupChildProps {
    handleButton: (state: number, comment?: string) => Promise<boolean>
    info: boolean
    setInfo: React.Dispatch<React.SetStateAction<boolean>>
    reject: boolean
    setReject: React.Dispatch<React.SetStateAction<boolean>>
    comment: string
    setComment: React.Dispatch<React.SetStateAction<string>>
    formRefState: React.RefObject<HTMLFormElement>
    errorMessages: Record<string, string>
    invest: Invest | undefined
    user: User
}
const FormTinyPopup = ({ handleButton,
    info,
    setInfo,
    reject,
    setReject,
    comment,
    setComment,
    formRefState,
    errorMessages,
    invest,
    user }: SalepointTinyPopupChildProps) => {

    const { law } = useLaw()

    const infoClick = () => {
        setInfo(true);
        setReject(false);
        if (info)
            handleButton(INFO);
    }
    const rejectClick = () => {
        setReject(true);
        setInfo(false);
        if (reject)
            handleButton(REJECT);
    }
    const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setComment(e.target.value)
    }

    const [localFormValues, setLocalFormValues] = useState({
        commentState: "",
    })


    const handleLocalChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let { name, value } = e.target;
        setLocalFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    }
    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setComment(value);
    }

    const canValid = (investPrice: number | undefined = 0) => {
        return (investPrice < 5000 && (isDg(user) || isDaf(user) || isManon(user) || isRaf(user)) && hasAdminRight(law)) ||
               ((isPdg(user) || isRonan(user)) && hasAdminRight(law))
    }

    return (
        <form ref={formRefState}>
            <p>
                {
                    (!info && !reject) ?
                        "En cliquant sur ces boutons, les personnes concernées seront notifiées automatiquement"
                        :
                        "Ajouter un commentaire et envoyer votre décision: "
                }
            </p>
            {
                ((localFormValues.commentState !== "" || reject || info) ?
                    <TextField className={styleModal.comment} fullWidth label="Commentaire"
                        size="small"
                        value={localFormValues.commentState}
                        name="commentState"
                        onChange={(e) => handleLocalChange(e)}
                        onBlur={handleBlur}
                        multiline
                        rows={4}
                        required
                        error={!!errorMessages.commentState}
                        helperText={errorMessages.commentState}
                    />
                    :
                    "")
            }
            <div className={styleModal.items + ((info || reject) ? " " + styleModal.alt : "")}>
                {
                    (info || reject) ?
                        <Button variant="contained"
                            onClick={() => { setReject(false); setInfo(false) }}
                            startIcon={<NavigateBeforeIcon />}>
                            Retour
                        </Button>
                        :
                        ""
                }
                {
                    (!reject && !info && canValid(invest?.price)) ?
                        <Button color='success' variant="contained"
                            onClick={() => handleButton(VALID)}
                            endIcon={<DoneIcon />}>
                            VALIDER
                        </Button>
                        :
                        ""
                }
                {
                    (!reject) ?
                        <Button color='info' variant="contained"
                            onClick={() => infoClick()}
                            endIcon={((info) ? <SendIcon /> : <QuestionMarkIcon />)}>
                            {(info) ? "Envoyer " : "Plus d'infos"}
                        </Button>
                        :
                        ""
                }
                {
                    (!info) ?
                        <Button color='error' variant="contained" onClick={() => rejectClick()}
                            endIcon={((reject) ? <SendIcon /> : <DoDisturbIcon />)}>
                            {(reject) ? "Envoyer " : "Refuser"}
                        </Button>
                        :
                        ""
                }
            </div>
        </form>
    )
}
export default FormTinyPopup;
